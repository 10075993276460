import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/page_home/aboutus_particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  padding: 0;
  margin-top: 120px;
  padding-top: 50px;
  padding-bottom: 120px;
  font-size: 15px;
  color: #3f3f3f;

  ${ media.greaterThan('lg')`
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -20%;
      background: url(${ Background }) no-repeat right top;
    }
  ` }
`

export const Mockup = styled.div`
  ${ media.lessThan('xxl')`
    width: 105%;
  ` }

  ${ media.lessThan('xl')`
    width: 50%;
    margin: 0 auto 30px auto;
  ` }

  ${ media.greaterThan('xl')`
    width: 105%;
    margin: 0 0;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(10%);
  ` }  

  ${ media.greaterThan('xxl')`
    width: 120%;
  ` }
`
