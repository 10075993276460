import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import media from 'styles'

const Wrapper = styled.div`
  position: absolute;
  top: -10%;
  left: 0;
  z-index: -1;

  width: 100%;

  ${ media.greaterThan('xl')`
    width: 1120px;
    top: -35%;
  ` };
`

const Image = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "page_home/aboutus_background.png" }) {
          childImageSharp {
            fluid(maxWidth: 1120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Img fluid={data.file.childImageSharp.fluid} alt="Tło" />
      </Wrapper>
    )}
  />
)

export default Image
