import styled from 'styled-components'
import { Container } from 'reactstrap'

import { HeadingMargin } from 'components/Heading'

const MarginTop = -120

export default styled(Container)`
  margin-top: ${ MarginTop }px;
  padding-top: ${ HeadingMargin - MarginTop }px;
  text-align: center;
`
