import styled from 'styled-components'

import iconWebsites from 'images/icons/websites.svg'
import iconApps from 'images/icons/apps.svg'
import iconMisc from 'images/icons/misc.svg'

export const Websites = styled.img.attrs({ src: iconWebsites })`
  height: 63px;
`

export const Apps = styled.img.attrs({ src: iconApps })`
  height: 62px;
`

export const Misc = styled.img.attrs({ src: iconMisc })`
  height: 68px;
`
