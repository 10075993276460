import styled from 'styled-components'

export const Wrapper = styled.li`
  color: #6d91be;
  font-size: 14px;
  text-align: left;
`

export const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: #2fac96;
`

export const Subtitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #dfe7f1;
  margin-bottom: 26px;
`
