import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Img from 'gatsby-image'

import * as Routes from 'routes'
import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import ButtonLink from 'components/Button/Link'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'
import Cards from 'components/Card/Cards'
import * as Card from 'components/Card'
import CardLink from 'components/Card/Link'
import * as Icons from 'components/Icon'
import Portfolio from 'components/Portfolio'
import Steps, { List as StepsList } from 'components/Steps'
import * as Step from 'components/Steps/Step'
import Partners from 'components/Partners'
import ContactWidget from 'components/ContactWidget'

import AboutUs, { Mockup as AboutUsMockup } from 'views/home/AboutUs'
import AboutUsBackground from 'views/home/AboutUs/Background'
import PortfolioSection from 'views/home/Portfolio'

const HomePage = ({ data }) => (
  <Layout withAddon header={header}>
    <Helmet title="nowoczesne usługi internetowe dla Twojego biznesu">
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>

    <Main>
      <Heading>
        <H4>
          <CompanyMark.Left /> Produkty od podstaw <CompanyMark.Right />
        </H4>
        <H2 className="text-xl-left">Stwórzmy razem coś niezwykłego</H2>
      </Heading>

      <Cards>
        <Container>
          <Row>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.OFFER_WEBSITES}>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Websites alt="Strony www" />
                  </Card.Icon>
                  <H3>Aplikacje WWW</H3>
                  <div className="mt-4">
                    W stronach i sklepach internetowych zajmujemy się każdym
                    aspektem. Od konsultacji, przez design i software, aż po
                    wdrożenie i stałe pozycjonowanie SEO. Wspólnie wprowadzimy
                    Twój biznes w sferę internetu - od podstaw do samego końca.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>

            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.OFFER_MOBILE_APPS}>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Apps alt="Aplikacje mobilne" />
                  </Card.Icon>
                  <H3>Aplikacje mobilne</H3>
                  <div className="mt-4">
                    Budujemy natywne i intuicyjne aplikacje mobilne na platformy
                    iOS i Android - dostępne w App Store i Google Play. Szukasz
                    partnera, który spełni Twoje oczekiwania i dodatkowo dorzuci
                    od siebie dobre praktyki? Świetnie trafiłeś!
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4">
              <Link to={Routes.OFFER_MISC_SERVICES}>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Misc alt="Pozostałe usługi" />
                  </Card.Icon>
                  <H3>Pozostałe usługi</H3>
                  <div className="mt-4">
                    Usługi w chmurze, Machine Learning, CRM'y, integracje,
                    automatyzacja oraz indywidualne rozwiązania. Pasja do
                    tworzenia produktów sprawia, że nie istnieją dla nas rzeczy
                    niemożliwe. Mamy za sobą niestandardowe projeky, które
                    odmieniły oblicza wielu firm.
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
          </Row>
        </Container>
      </Cards>

      <AboutUs id="o-nas">
        <AboutUsBackground />
        <Container>
          <Row>
            <div className="col-12 col-xl align-self-end">
              <AboutUsMockup>
                <Img
                  fluid={data.aboutUsMockup.childImageSharp.fluid}
                  alt="O nas"
                />
              </AboutUsMockup>
            </div>
            <div className="col-12 col-xl">
              <Heading left secondary noBackground>
                <H4 className="text-center text-xl-left">
                  <CompanyMark.Left /> Twój partner IT <CompanyMark.Right />
                </H4>
                <H2 className="text-center text-xl-left">Kim jesteśmy?</H2>
                <div className="mt-5 text-center text-xl-left">
                  Karer Media to Digital Agency różniący się od standardowych
                  firm IT. Nie ograniczamy się do programowania i tworzymy
                  produkty od podstaw do końca. Nasi doświadczeni pasjonaci
                  pracują na każdym etapie - doradczym, projektowym,
                  programistycznym i wdrożeniowym. Finalną jakość stawiamy
                  zawsze na pierwszym miejscu.
                  <br />
                  <br />
                  Rozwój Twojego biznesu w sferze internetu staje się z nami
                  zjawiskowo prosty. Zadbamy, aby każdy detal został dopięty na
                  ostatni guzik pod wszystkimi względami, m.in. wyglądu,
                  funkcjonalności, wydajności, trendów i intuicyjności obsługi.
                  Końcowy produkt to taki, z którego w pełni zadowoleni jesteśmy
                  wspólnie Ty oraz my.
                  <br />
                  <ButtonLink
                    to="#"
                    variant="secondary"
                    className="mt-4"
                    onClick={e => {
                      e.preventDefault()

                      document
                        .getElementById('nasze-realizacje')
                        .scrollIntoView({ behavior: 'smooth' })
                    }}
                  >
                    Poznaj nas lepiej
                  </ButtonLink>
                </div>
              </Heading>
            </div>
          </Row>
        </Container>
      </AboutUs>

      <Heading id="nasze-realizacje">
        <H4>
          <CompanyMark.Left /> Obrazy znaczą więcej, niż tysiąc słów{' '}
          <CompanyMark.Right />
        </H4>
        <H2>Nasze realizacje</H2>
      </Heading>

      <PortfolioSection>
        <Row>
          <Portfolio mode="home" />
        </Row>
        <Row>
          <ButtonLink
            to={Routes.PORTFOLIO}
            variant="primary"
            className="my-4 mx-auto"
          >
            Zobacz całe portfolio
          </ButtonLink>
        </Row>
      </PortfolioSection>

      <Steps>
        <Container>
          <Row>
            <Heading noBackground light>
              <H4>
                <CompanyMark.Left /> Przebieg współpracy <CompanyMark.Right />
              </H4>
              <H2>Jak działamy?</H2>
            </Heading>
          </Row>
          <Row>
            <StepsList>
              <Step.Wrapper>
                <Step.Title>KROK 1</Step.Title>
                <Step.Subtitle>Warsztat</Step.Subtitle>
                <div>
                  Zanim przejdziemy do konkretów, poznajemy Ciebie i Twoje
                  oczekiwania. Proponujemy najoptymalniejsze rozwiązania,
                  kreujemy przekonania, dzielimy się doświadczeniem. Na żywo,
                  online, telefonicznie, przez Messengera - tak, jak jest Ci
                  najwygodniej. Rozpoczęcie współpracy od zaliczki? Nie u nas.
                </div>
              </Step.Wrapper>
              <Step.Wrapper>
                <Step.Title>KROK 2</Step.Title>
                <Step.Subtitle>Implementacja</Step.Subtitle>
                <div>
                  Kiedy wiemy czego oczekujesz, przechodzimy do sedna -
                  estymacje, specyfikacje i makiety. Z doświadczenia wiemy, że
                  potrzeby zmian zaczynają być widoczne dopiero w miarę rozwoju
                  projektu. Dzięki organizacji metodą Agile, na bieżąco trafnie
                  dostosowujemy pracę do zamierzonego celu.
                </div>
              </Step.Wrapper>
              <Step.Wrapper>
                <Step.Title>KROK 3</Step.Title>
                <Step.Subtitle>Wdrożenie i utrzymanie</Step.Subtitle>
                <div>
                  Kiedy my nieustannie pracujemy nad Twoim projektem, Ty
                  skupiasz się na prowadzeniu biznesu. Nawet po zakończeniu
                  prac, nadal doradzamy, proponujemy i do tego dajemy Ci
                  gwarancję na stworzony produkt. Jako partner biznesowy
                  wspólnie zależy nam przecież na jego sukcesie.
                </div>
              </Step.Wrapper>
            </StepsList>
          </Row>
        </Container>
      </Steps>

      <Partners />

      <ContactWidget title="Kontakt z nami" subtitle="Poznajmy się" />
    </Main>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        image
        description
      }
    }
    aboutUsMockup: file(relativePath: { eq: "page_home/aboutus_mockup.png" }) {
      childImageSharp {
        fluid(maxWidth: 721, maxHeight: 570) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const header = {
  title: (
    <Fragment>
      Tworzymy nowoczesne usługi internetowe dla Twojego biznesu<span>.</span>
    </Fragment>
  ),
  subtitle: (
    <Fragment>
      Cześć. Jesteśmy <CompanyMark.Left /> Karer Media <CompanyMark.Right />
    </Fragment>
  ),
  content: (
    <div>
      <div className="my-5">
        Na miarę XXI wieku. Innowacyjne, stabilne, spersonalizowane.
      </div>
      <ButtonLink
        to="#"
        variant="primary"
        className="mb-2 mb-md-0"
        onClick={e => {
          e.preventDefault()

          document
            .getElementById('o-nas')
            .scrollIntoView({ behavior: 'smooth' })
        }}
      >
        O nas
      </ButtonLink>
      <ButtonLink to={Routes.CONTACT} variant="secondary" className="ml-md-3">
        Kontakt
      </ButtonLink>
    </div>
  ),
  image: 'home'
}

export default HomePage
