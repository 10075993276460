import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import { HeadingMargin } from 'components/Heading'

export default styled(Container).attrs({ fluid: true })`
  padding: ${ HeadingMargin }px 0 0 0;
`

export const List = styled.ul`
  width: 100%;
  display: grid;

  grid-template-columns: repeat(2, 1fr);

  ${ media.greaterThan('lg')`
    grid-template-columns: repeat(5, 1fr);
  ` }
`

export const Partner = styled.li`
  display: flex;
  margin-top: -1px;
  height: 145px;

  ${ media.greaterThan('lg')`
    height: 160px;
  ` }

  ${ media.greaterThan('xl')`
    height: 250px;
  ` }

  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  background: #ffffff;

  text-align: center;
  justify-content: center;
  align-items: center;

  &:nth-child(5n + 1) {
    border-left: none;
  }

  &:nth-child(even) {
    background: #f9f8f8;
  }

  > a {
    display: block;
    width: 42%;
  }
`
