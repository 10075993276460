import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Heading from 'components/Heading'
import * as CompanyMark from 'components/CompanyMark'
import H2 from 'components/H2'
import H4 from 'components/H4'

import Wrapper, { List, Partner } from './Wrapper'

const mocks = {
  orlen: { name: 'Orlen', url: 'https://orlen.pl' },
  jarock: { name: 'Ja, Rock!', url: 'https://jarock.pl' },
  showit: { name: 'show-it', url: 'https://show-it.tv' },
  czynnaniedziela: {
    name: 'Czynna Niedziela',
    url:
      'https://play.google.com/store/apps/details?id=pl.karermedia.czynnaniedziela'
  },
  devgaming: { name: 'devgaming', url: 'https://devgaming.pl' },
  dreamcars: { name: 'Dream Cars', url: 'https://dreamcars-rent.pl' },
  fortnitejoker: { name: 'Fortnite Joker', url: 'https://fortnitejoker.com' },
  clickdonate: { name: 'ClickDonate', url: '#' },
  clicksell: { name: 'ClickSell', url: '#' },
  uwiugi: { name: 'UWI-UGI', url: 'https://uwi-ugi.com' }
}

const mocksKeys = Object.keys(mocks)

const Partners = () => (
  <StaticQuery
    query={query}
    render={data => (
      <Fragment>
        <Heading>
          <H4>
            <CompanyMark.Left /> Współpracowali z nami <CompanyMark.Right />
          </H4>
          <H2>Nasi klienci</H2>
        </Heading>

        <Wrapper>
          <List>
            {mocksKeys.map(key => (
              <Partner key={key}>
                <a href={mocks[key].url} target="_blank">
                  <Img
                    fluid={data[key].childImageSharp.fluid}
                    alt={mocks[key].name}
                  />
                </a>
              </Partner>
            ))}
          </List>
        </Wrapper>
      </Fragment>
    )}
  />
)

const query = graphql`
  query {
    clickdonate: file(relativePath: { eq: "partners/clickdonate.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    clicksell: file(relativePath: { eq: "partners/clicksell.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    czynnaniedziela: file(
      relativePath: { eq: "partners/czynnaniedziela.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    devgaming: file(relativePath: { eq: "partners/devgaming.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dreamcars: file(relativePath: { eq: "partners/dreamcars.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    fortnitejoker: file(relativePath: { eq: "partners/fortnitejoker.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    jarock: file(relativePath: { eq: "partners/jarock.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    orlen: file(relativePath: { eq: "partners/orlen.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    showit: file(relativePath: { eq: "partners/showit.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    uwiugi: file(relativePath: { eq: "partners/uwiugi.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Partners
